<template>
  <div class="margin-top-30 padding-bottom-30" v-loading="loading">
    <div style="margin: 0 5vw;" v-if="refundDetailData">
      <div class="detail_box">
        <div class="detail_box_title"><label>售后类型:</label><span class="red">{{refundType[refundDetailData.type]}}</span></div>
        <div class="">
          <div class="detail_box_item">
            <div class="detail_box_item_left" style="width: 60%;display: flex">
              <label>售后状态:</label><span class="red">{{refundStatus[refundDetailData.status]}}</span>
              <CountDown v-if="refundDetailData.status == 3" style="color: red;transform: scale(1.4);width: 45%;text-align: right;margin-top: 7px;" :time="parseInt(refundDetailData.userSendTime) + 1000 * 60 * 60 * 24 * 10" format="还剩dd天hh小时mm分自动确认收货" @end="endTheTimer()" />
            </div>
            <div class="detail_box_item_right pull_right">

              <el-popover v-if="refundDetailData.status === 1" trigger="click" v-model="refundDetailData.showY" placement="left" width="320">
                <div class="text-center padding-10">
                  <div class="f16 b">{{refundDetailData.type === 1 ? '同意退款？' : refundDetailData.type === 2 ? '同意退货？' : '同意换货？'}}</div>
                </div>
                <div class="text-left padding-10" v-if="refundDetailData.type === 1">{{`确认退款后，退款金额￥${refundDetailData.realRefundAmount}将直接退入买家微信支付账户内`}}</div>
                <div class="padding-10" v-else>
                  <div class="" v-if="refundAddress.length === 0 || !refundDetailData.useDefaultRefundAddress || isEmpty(defaultRefundAddress)">
                    <div class="divide-list flex cursor-pointer" v-for="(address,index) in refundAddress" @click="refundDetailData.refundAddressIndex = index" :key="index">
                      <div class="flex-1">
                        <el-tag size="mini" v-if="address.isDefault === 1">默认</el-tag>
                        {{`${address.addressInfo} ，${address.receiveName}，${address.receivePhone}`}}
                      </div>
                      <div class="margin-left" style="width: 50px;font-size: 24px">
                        <i v-if="index === refundDetailData.refundAddressIndex" class="el-icon-check"></i>
                      </div>
                    </div>
                  </div>
                  <div class="" v-else>
                    {{`默认退回地址：${defaultRefundAddress.addressInfo} ，${defaultRefundAddress.receiveName}，${defaultRefundAddress.receivePhone}`}}
                  </div>
                  <div class="" v-if="refundAddress.length === 0 || !refundDetailData.useDefaultRefundAddress || isEmpty(defaultRefundAddress)">
                    <el-link href="/refundAddressList" type="primary" :underline="false" target="_blank"><i class="el-icon-plus"></i>添加新地址</el-link>
                  </div>
                  <el-button type="text" v-else @click="refundDetailData.useDefaultRefundAddress = false">切换地址</el-button>
                </div>
                <div class="text-center padding-10">
                  <el-button type="info" size="mini" @click="refundDetailData.showY = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="reviewSuccess(refundDetailData)">{{refundDetailData.type === 1 ? '同意退款' : refundDetailData.type === 2 ? '同意退货' : '同意换货'}}</el-button>
                </div>
                <el-button class="function-button" slot="reference" size="medium" type="primary">同意</el-button>
              </el-popover>

              <el-popover v-if="refundDetailData.status === 1" trigger="click" v-model="refundDetailData.showN" placement="left" width="320">
                <div class="text-center padding-10">
                  <div class="f16 b">拒绝的原因</div>
                </div>
                <div class="padding-10">
                  <el-input type="textarea" :rows="5" resize="none" placeholder="必须输入拒绝的原因才可以拒绝" v-model="refundDetailData.refuseRemark" maxlength="30" show-word-limit></el-input>
                </div>
                <div class="text-center padding-10">
                  <el-button type="info" size="mini" @click="refundDetailData.showN = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="reviewRefuse(refundDetailData)" :disabled="!refundDetailData.refuseRemark">确定</el-button>
                </div>
                <el-button class="function-button" slot="reference" size="medium" type="danger">拒绝</el-button>
              </el-popover>

              <el-popover v-if="refundDetailData.status === 3" trigger="click" v-model="refundDetailData.showY" placement="left" width="320">
                <div class="text-center padding-10">
                  <div class="f16 b">{{refundDetailData.type === 2 ? '确认收货并退款？' : '确认收货？'}}</div>
                </div>
                <div class="text-left padding-10" v-if="refundDetailData.type === 2">{{`确认收到货物，并允许退款￥${refundDetailData.realRefundAmount}`}}</div>
                <div class="text-left padding-10" v-if="refundDetailData.type === 3">{{'收到货物后，请尽快为客户换货发回去'}}</div>
                <div class="text-center padding-10">
                  <el-button type="info" size="mini" @click="refundDetailData.showY = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="confirmReceive(refundDetailData)">确定</el-button>
                </div>
                <el-button class="function-button" slot="reference" size="medium" type="primary">确认收货</el-button>
              </el-popover>

              <el-popover v-if="refundDetailData.status === 3" trigger="click" v-model="refundDetailData.showN" placement="left" width="320">
                <div class="text-center padding-10">
                  <div class="f16 b">没收到货?</div>
                </div>
                <div class="text-left padding-10">{{'确定没收到客户寄回的货物，拒绝这次售后?'}}</div>
                <div class="text-center padding-10">
                  <el-button type="info" size="mini" @click="refundDetailData.showN = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="confirmRefuse(refundDetailData)">确定</el-button>
                </div>
                <el-button class="function-button" slot="reference" size="medium" type="danger">未收到货</el-button>
              </el-popover>

              <el-popover v-if="refundDetailData.status === 4 && refundDetailData.type === 3" trigger="click" v-model="refundDetailData.showS" placement="left" width="320">
                <div class="text-center padding-10">
                  <div class="f16 b">选择发货快递</div>
                </div>
                <div class="padding-10 text-center">
                  <el-select v-model="refundDetailData.showSexpressCode" placeholder="请选择快递公司" style="width:250px;">
                    <el-option v-for="item in expressCompanyArr" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                  </el-select>
                  <el-input class="margin-top-10" v-model="refundDetailData.showSexpressSn" placeholder="请选输入快递单号" style="width:250px;"></el-input>
                </div>
                <div class="text-center padding-10">
                  <el-button type="info" size="mini" @click="refundDetailData.showS = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="sendOrder(refundDetailData)">确定</el-button>
                </div>
                <el-button class="function-button" slot="reference" size="medium" type="primary">发货</el-button>
              </el-popover>

            </div>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="detail_left">
          <div class="detail_box">
            <div class="detail_box_title">售后信息</div>
            <div class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">售后单号</div>
                <div class="detail_box_item_right b">{{refundDetailData.id}}</div>
              </div>
			  <div class="detail_box_item">
                <div class="detail_box_item_left">申请内容</div>
                <div class="detail_box_item_right">{{refundDetailData.remark}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">申请时间</div>
                <div class="detail_box_item_right">{{refundDetailData.applyTime_text}}</div>
              </div>
			  <div class="detail_box_title">售后商品</div>
              <div class="detail_box_item">
                  <div class="detail_box_item_goods">
					<template>
                      <el-table
                        :data="refundDetailData.orderGoodsListJson"
                        :header-cell-style="{background:'#f2f2f2'}"
                        >
                        <el-table-column
                          label="商品信息"
                          width="300">
                          <template slot-scope="scope">
                            <div class="flex" style="padding: 3px 0;">
                              <el-image class="" style="width: 64px;height: 64px;" :src="scope.row.cover"></el-image>
                              <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                                <div class="goods-name flex-1">{{scope.row.goodsName}}</div>
                                <div class="gray">{{scope.row.skuName}}</div>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="商品数量"
                          >
                          <template slot-scope="scope">
                            X{{scope.row.goodsNum}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="商品价格"
                          >
                          <template slot-scope="scope">
                            ¥{{scope.row.price}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="supplierName"
                          label="店铺名称"
                          >
                        </el-table-column>
                      </el-table>
                    </template>
                    <!-- <div class="goods_out_left">
                      <el-image style="width: 62px; height: 62px" :src="refundDetailData.refundGoodsInfo.cover" v-if="!isEmpty(refundDetailData.refundGoodsInfo.cover)"></el-image>
                    </div>
                    <div class="goods_out_right">
                      <div class="out_right_left">
                        <div class="goods_name">{{refundDetailData.refundGoodsInfo.goodsName}}</div>
                        <div class="gray">{{refundDetailData.refundGoodsInfo.skuName}}</div>
                      </div>
                      <div class="out_right_right">
                        <div class="goods_price">¥{{refundDetailData.refundGoodsInfo.price}}</div>
                        <div class="gray">x{{refundDetailData.refundGoodsInfo.goodsNum}}</div>
                      </div>
                    </div> -->
                </div>
              </div>

              <div class="detail_box_item" v-if="!isEmpty(refundDetailData.credentials)">
                <div class="detail_box_item_left">申请凭证</div>
                <div class="detail_box_item_right">
                  <img v-for="item in refundDetailData.credentials" :src="item" style="width:100px;height:100px;margin:5px;object-fit: cover;" @click="showBigImg(item)"/>
                </div>
              </div>
              <div class="detail_box_item" v-if="false">
                <div class="detail_box_item_left">售后类型</div>
                <div class="detail_box_item_right pull_right red">{{refundType[refundDetailData.type]}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.type === 1">
                <div class="detail_box_item_left">货物状态</div>
                <div class="detail_box_item_right">{{refundDetailData.orderStatus==4?'已收到货':refundGoodsExpressStatus[refundDetailData.goodsExpressStatus]}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.applyAmount">
                <div class="detail_box_item_left">商品退款</div>
                <div class="detail_box_item_right">￥{{refundDetailData.applyAmount}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.freightAmount">
                <div class="detail_box_item_left">运费退款</div>
                <div class="detail_box_item_right">￥{{refundDetailData.freightAmount}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.applyAmount + refundDetailData.freightAmount">
                <div class="detail_box_item_left">总应退款</div>
                <!-- <div class="detail_box_item_right">￥{{ refundDetailData.goodsExpressStatus == 1 ? (refundDetailData.applyAmount + refundDetailData.freightAmount):refundDetailData.applyAmount }}</div> -->
                <div class="detail_box_item_right">￥{{ refundDetailData.applyAmount + refundDetailData.freightAmount}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.status === 6">
                <div class="detail_box_item_left">实际退款</div>
                <div class="detail_box_item_right">￥{{refundDetailData.realRefundAmount}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.reviewTime_text">
                <div class="detail_box_item_left">审核时间</div>
                <div class="detail_box_item_right">{{refundDetailData.reviewTime_text}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.reviewerMessage">
                <div class="detail_box_item_left">审核备注</div>
                <div class="detail_box_item_right">{{refundDetailData.reviewerMessage}}</div>
              </div>
              <div class="detail_box_item" v-if="refundDetailData.refundSuccessTime_text">
                <div class="detail_box_item_left">完成时间</div>
                <div class="detail_box_item_right">{{refundDetailData.refundSuccessTime_text}}</div>
              </div>
            </div>
          </div>
          <div class="detail_box">
            <div class="detail_box_title">订单信息</div>
            <div class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">订单状态</div>
                <div class="detail_box_item_right b">{{orderStatus[refundDetailData.orderStatus]}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">订单号</div>
                <div class="detail_box_item_right">{{refundDetailData.orderId}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">下单时间</div>
                <div class="detail_box_item_right">{{refundDetailData.createTime_text}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">支付时间</div>
                <div class="detail_box_item_right">{{refundDetailData.payTime_text}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">支付单号</div>
                <div class="detail_box_item_right">{{refundDetailData.outTradeNo}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail_right">

          <div class="detail_box">
            <div class="detail_box_title">客户寄回物流信息</div>
            <div v-if="!isEmpty(refundDetailData.expressSn)" class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">收件信息</div>
                <div class="detail_box_item_right">{{`${refundDetailData.supplierReceiveAddressInfo} ，${refundDetailData.supplierReceiveName} ，${refundDetailData.supplierReceivePhone}`}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">{{refundDetailData.expressName}}</div>
                <div class="detail_box_item_right red f16 b">{{refundDetailData.expressSn}}</div>
                <div class=""><el-button type="text" @click="customerExpressDetail.foldExpress = !customerExpressDetail.foldExpress"> {{!customerExpressDetail.foldExpress?'折叠':'展开'}} </el-button></div>
              </div>
              <div class="padding-right" :style="!customerExpressDetail.foldExpress?'height:100%;':'height:100px;overflow: hidden;'">
                <el-timeline >
                  <el-timeline-item v-for="(activity, index) in customerExpressDetail.data" :color="activity.color" :key="index"
                                    :timestamp="activity.time">{{activity.context}}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div class="" v-else>暂无物流信息</div>
          </div>

          <div class="detail_box">
            <div class="detail_box_title">商家重新寄出物流信息</div>
            <div v-if="!isEmpty(refundDetailData.supplierExpressSn)" class="">
              <div class="detail_box_item">
                <div class="detail_box_item_left">收件信息</div>
                <div class="detail_box_item_right">{{`${refundDetailData.receiveAddressInfo} ，${refundDetailData.receiveName} ，${refundDetailData.receivePhone}`}}</div>
              </div>
              <div class="detail_box_item">
                <div class="detail_box_item_left">{{refundDetailData.supplierExpressName}}</div>
                <div class="detail_box_item_right red f16 b">{{refundDetailData.supplierExpressSn}}</div>
                <div class=""><el-button type="text" @click="supplierExpressDetail.foldExpress = !supplierExpressDetail.foldExpress"> {{!supplierExpressDetail.foldExpress?'折叠':'展开'}} </el-button></div>
              </div>
              <div class="padding-right" :style="!supplierExpressDetail.foldExpress?'height:100%;':'height:100px;overflow: hidden;'">
                <el-timeline >
                  <el-timeline-item v-for="(activity, index) in supplierExpressDetail.data" :color="activity.color" :key="index"
                                    :timestamp="activity.time">{{activity.context}}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div class="" v-else>暂无物流信息</div>
          </div>

        </div>
      </div>
    </div>
    <div class="fixed-action-right-bottom edit-goods-action">
      <el-button type="" @click="goBack"><el-icon class="el-icon-back"/>返回上页</el-button>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="40%" append-to-body>
      <img width="100%" height="750px;" style="object-fit: contain;" :src="dialogImageUrl">
    </el-dialog>
  </div>
</template>

<script>
  import {isEmpty,parseTimeObject,goBack} from '../../../assets/js/utils'
  import {orderStatus,refundStatus,refundType,refundGoodsExpressStatus} from '../../../assets/js/status'
  import {URL} from '../../../config'
  import CountDown from '../../../components/countDown.vue'

  export default {
    name: 'orderRefundDetail',
    components:{
      CountDown
    },
    computed:{
      defaultRefundAddress(){
        let list =  this.refundAddress.filter(res=>res.isDefault === 1);
        if(list.length){return list[0]}
        return {};
      }
    },
    data(){
      return {
        orderStatus,
        refundStatus,
        refundType,
        refundGoodsExpressStatus,
        formLabelWidth: '150px',
        refundDetailData:{refundGoodsInfo:{}},
        loading:false,
        customerExpressDetail:{
          foldExpress:true,
          data:[],
        },
        supplierExpressDetail:{
          foldExpress:true,
          data:[],
        },
        refundAddress:[],//售后退回地址
        expressCompanyArr:[],
        dialogVisible:false,
        dialogImageUrl:"",
        refundId:"",
      }
    },
    created() {
      this.getRefundDetail();
      this.getExpressCompany();
      this.getRefundAddress();
    },
    methods:{
      isEmpty,
      //倒计时结束
      endTheTimer() {
        this.$router.go(0);
      },
      viewCustomerExpress(){
        let data = {
          expressSn:this.refundDetailData.expressSn,
          phone:this.refundDetailData.supplierReceivePhone,
        };
        this.axios.get(URL.supplierOrder.express,{params:data}).then(res=>{
          let expressDetails = res.data.expressDetails;
          if(!isEmpty(expressDetails)){
            try {
              Object.assign(this.customerExpressDetail,JSON.parse(expressDetails))
            }catch (e) {
              this.customerExpressDetail = {}
            }
          }
        }).catch(res=>{
        }).finally(()=>{
          this.customerExpressDetail.foldExpress = true;
        })
      },
      viewSupplierExpress(){
        let data = {
          expressSn:this.refundDetailData.supplierExpressSn,
          phone:this.refundDetailData.receivePhone,
        };

        this.axios.get(URL.supplierOrder.express,{params:data}).then(res=>{
          let expressDetails = res.data.expressDetails;
          if(!isEmpty(expressDetails)){
            try {
              Object.assign(this.supplierExpressDetail,JSON.parse(expressDetails))
            }catch (e) {
              this.supplierExpressDetail = {}
            }
          }
        }).catch(res=>{
        }).finally(()=>{
          this.supplierExpressDetail.foldExpress = true;
        })
      },
      goBack,
      showBigImg(url){
        this.dialogVisible = true;
        this.dialogImageUrl = url;
      },
      getRefundDetail(){
        let params = this.$route.query;
        this.refundId = params.refundId;
        return new Promise((resolve, reject) => {
          let data = {refundId:this.refundId};
          this.loading = true;
          let url = URL.supplierOrder.refundOrderDetail;
          this.axios.get(url,{params:data}).then(res=>{
            parseTimeObject(res.data,["createTime","payTime","applyTime","reviewTime", "refundSuccessTime"]);
            res.data.showY = false;
            res.data.showN = false;
            res.data.refuseRemark = '';
            res.data.useDefaultRefundAddress = true;
            res.data.refundAddressIndex = 0;
            res.data.showS = false;
            res.data.showSexpressCode = '';
            res.data.showSexpressSn = '';

            this.refundDetailData = res.data;//为了双向绑定事件，这里最后赋值
            if (res.data.orderGoodsListJson) {
              this.refundDetailData.orderGoodsListJson = JSON.parse(res.data.orderGoodsListJson)
            } else {
              this.refundDetailData.orderGoodsListJson = []
            }
			console.log(this.refundDetailData.orderGoodsListJson,12313)
            if(this.refundDetailData.expressSn){
              this.viewCustomerExpress()
            }
            if(this.refundDetailData.supplierExpressSn){
              this.viewSupplierExpress();
            }
            resolve(res);
          }).catch(res=>{
          }).finally(()=>{
            this.loading = false;
          })
        })
      },
      getExpressCompany(){
        let name = "";
        return new Promise((resolve, reject) => {
          this.axios.get(URL.expressCompany.list,{name:name}).then(res=>{
            this.expressCompanyArr = res.data;
            resolve(res);
          }).catch(()=>{
            this.tableData = [];
          }).finally(res=>{
          })
        })
      },
      getRefundAddress(){
        return new Promise((resolve, reject) => {
          this.axios.get(URL.supplier.refundAddress,{params:{type:4}}).then(res=>{
            if (res.code !== 0) {
              this.$message.error(res.msg);
            } else {
              this.refundAddress = res.data || [];
            }
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
          })
        })
      },
      reviewSuccess(refund){
        refund.showY = false;
        let selectedRefundAddress = {};
        if(refund.type === 2 || refund.type === 3){
          if(this.refundAddress.length === 0){
            this.$message.error("请先去添加退货地址");
            return false;
          }
          // if(refund.useDefaultRefundAddress){
          //   selectedRefundAddress = this.defaultRefundAddress;
          // }else {
          //   selectedRefundAddress = this.refundAddress[refund.refundAddressIndex];
          // }
          selectedRefundAddress = this.refundAddress[refund.refundAddressIndex]
          if(isEmpty(selectedRefundAddress)){
            this.$message.error("请选择售后寄回地址");
            return false;
          }
        }
        let data = {
          refundId:refund.id,
          realRefundAmount: refund.realRefundAmount,
          refundAddressJson: JSON.stringify(selectedRefundAddress),
          addressId:selectedRefundAddress.id
        };
        this.loading = true;
        this.axios.post(URL.supplierOrder.reviewRefundSuccess, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getRefundDetail();
          }else{
            this.$message.error(res.msg);
          }
        }).catch(()=>{

        }).finally(()=>{
        });
      },
      reviewRefuse(refund) {
        refund.showN = false;
        let data = {
          refundId:refund.id,
          remark:refund.refuseRemark,
        };
        this.axios.post(URL.supplierOrder.reviewRefundRefuse, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getRefundDetail();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      confirmReceive(refund){
        refund.showY = false;
        this.loading = true
        let data = {
          refundId:refund.id
        };
        this.axios.post(URL.supplierOrder.refundConfirmRefund, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getRefundDetail();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      confirmRefuse(refund){
        refund.showN = false;
        this.loading = true
        let data = {
          refundId:refund.id
        };
        this.axios.post(URL.supplierOrder.operatingNotReceivedOrder, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getRefundDetail();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      sendOrder(refund){
        refund.showS = false;
        let data = {
          refundId:refund.id,
          expressCode:refund.showSexpressCode,
          expressSn:refund.showSexpressSn,
        };
        this.loading = true;
        this.axios.post(URL.supplierOrder.refundOrderReSend,this.qs.stringify(data)).then(res=>{
          if(res.code !== 0){
            this.$message({
              type: 'error',
              message: res.msg
            });
          }else{
            this.getRefundDetail()
          }
        }).catch(res=>{
        }).finally(()=>{
          this.loading = false;
        });
      },
    }
  }

</script>

<style lang="less" scoped>
  @import "../../../assets/css/order";
</style>
